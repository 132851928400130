import { Icon } from '@dnb/eufemia';
import {
  chevron_down_medium as ChevronDownIcon,
  chevron_right_medium as ChevronRightIcon,
} from '@dnb/eufemia/icons';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import remarkParse from 'remark-parse';
import slugify from 'slugify';
import { unified } from 'unified';

import { useScrollSpy } from '@/hooks/useScrollSpy';

import Search from './Search';

interface NavigationType {
  name: string;
  description: string;
  parent?: string;
  key: string;
  children: NavigationType[];
}

const flattenArr = (arr: NavigationType[]) => {
  const result: NavigationType[] = [];

  arr.forEach((item) => {
    const { name, key, children, description, parent } = item;

    result.push({ name, key, description, children: [], parent });

    if (children) result.push(...flattenArr(children));
  });

  return result;
};

const extractHeadings = (markdownContent: string) => {
  const processor = unified().use(remarkParse);
  const ast = processor.parse(markdownContent);

  const headings: { level: number; text: string }[] = [];
  ast.children.forEach((node) => {
    if (node.type === 'heading' && (node.depth === 1 || node.depth === 2)) {
      headings.push({
        level: node.depth,
        text: node.children.map((child: any) => child.value).join(''),
      });
    }
  });

  return headings;
};

const GuideTableOfContent = ({
  guide,
}: {
  guide: string | null | undefined;
}): JSX.Element => {
  const navigate = useNavigate();
  const [links, setLinks] = useState<NavigationType[] | undefined>();
  const flattenLinks = useMemo(() => (links ? flattenArr(links) : []), [links]);
  const selectedItem = useScrollSpy(flattenLinks.map((li) => li.key));
  const [selectedMenuItem, setSelectedMenuItem] = useState<
    NavigationType | undefined
  >();
  const [search, setSearch] = useState<string[] | undefined>();

  useEffect(() => {
    setSelectedMenuItem(flattenLinks.find((item) => item.key === selectedItem));
    if (selectedItem) navigate(`#${selectedItem}`, { replace: true });
  }, [navigate, selectedItem, flattenLinks]);

  useEffect(() => {
    const myLinks: NavigationType[] = [];
    const parsedData = guide ? extractHeadings(guide) : [];
    const hasH1 = parsedData.length > 0 && parsedData[0].level === 1;

    parsedData.forEach((item) => {
      const name = item.text;
      if (item.level === 2 && hasH1) {
        myLinks.at(-1)?.children.push({
          name,
          parent: myLinks.at(-1)?.key,
          description: name,
          key: 'heading/' + slugify(name.toLowerCase(), { trim: false }),
          children: [],
        });
      } else {
        myLinks.push({
          name,
          description: name,
          key: 'heading/' + slugify(name.toLowerCase(), { trim: false }),
          children: [],
        });
      }
    });

    setLinks(myLinks);

    window.setTimeout(() => {
      document
        .getElementById(window.location.hash.replace('#', ''))
        ?.scrollIntoView({ behavior: 'auto' });
    }, 500);
  }, [guide]);

  const renderLink = (link: NavigationType) => {
    const active = [selectedMenuItem?.key, selectedMenuItem?.parent].includes(
      link.key,
    );

    return (
      <li
        className={`${active ? ' active' : ''}`}
        key={`ref-links-${link.key}`}
      >
        <a href={`#${link.key}`}>{link.name}</a>
        {link.children.length > 0 && (
          <Icon
            color="var(--color-sea-green)"
            icon={active ? ChevronDownIcon : ChevronRightIcon}
          />
        )}
        {active && link.children.length > 0 && (
          <ul>
            {link.children.map((sub) => (
              <li
                className={`sub${selectedItem === sub.key ? ' active' : ''}`}
                key={`ref-links-${sub.key}`}
              >
                <a href={`#${sub.key}`}>{sub.name}</a>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <div className="__left">
      <div className="sticker">
        {flattenLinks && (
          <Search items={flattenLinks} searchResult={setSearch} />
        )}
        <div className="scroll-content">
          {links && (
            <ul>
              {search
                ? flattenLinks
                    .filter((link) => search.includes(link.name))
                    .map(renderLink)
                : links.map(renderLink)}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuideTableOfContent;
