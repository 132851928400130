import { Tabs } from '@dnb/eufemia';
import { SchemaType } from '@portals/shared/common/open-api/openapi-helpers';
import { useState } from 'react';

import { DereferencedMediaTypeObject } from '@/pages/api-documentation/constants/types';

import ExampleView from './ExampleView';
import SchemaView from './SchemaView';

interface Props {
  mediaTypeObject: DereferencedMediaTypeObject;
  contentType: string;
  schemaType: SchemaType;
}

export default function MediaType({
  mediaTypeObject,
  contentType,
  schemaType,
}: Props) {
  const [selectedTab, setSelectedTab] = useState('schema');

  return (
    <div>
      <Tabs
        data={[
          { title: 'Schema', key: 'schema' },
          { title: 'Example', key: 'example' },
        ]}
        no_border
        on_change={({ key }) => setSelectedTab(key)}
        selected_key={selectedTab}
      />
      <div>
        {selectedTab === 'schema' ? (
          <SchemaView schema={mediaTypeObject.schema} schemaType={schemaType} />
        ) : (
          <ExampleView
            contentType={contentType}
            mediaTypeObject={mediaTypeObject}
            schemaType={schemaType}
          />
        )}
      </div>
    </div>
  );
}
