import { Paragraph } from '@dnb/eufemia';
import { SchemaType } from '@portals/shared/common/open-api/openapi-helpers';

import { DereferencedMediaTypeObject } from '@/pages/api-documentation/constants/types';

import Example from '../../../Example';
import NamedExamples from '../../../NamedExamples';
import SchemaExample from '../../../SchemaExample';

interface Props {
  mediaTypeObject: DereferencedMediaTypeObject;
  contentType: string;
  schemaType: SchemaType;
}

export default function ExampleView({
  mediaTypeObject,
  contentType,
  schemaType,
}: Props) {
  if (mediaTypeObject.examples) {
    return (
      <NamedExamples
        contentType={contentType}
        examples={mediaTypeObject.examples}
      />
    );
  }

  if (mediaTypeObject.example) {
    return (
      <Example contentType={contentType} example={mediaTypeObject.example} />
    );
  }

  if (mediaTypeObject.schema) {
    return (
      <SchemaExample
        contentType={contentType}
        schema={mediaTypeObject.schema}
        schemaType={schemaType}
      />
    );
  }

  return (
    <Paragraph top="small">
      <i>Example not provided.</i>
    </Paragraph>
  );
}
