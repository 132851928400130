import {
  InteractionRequiredAuthError,
  PublicClientApplication,
  type RedirectRequest,
} from '@azure/msal-browser';

export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'profile', 'email', `api://${AZURE_CLIENT_ID}/User`],
  domainHint: 'dnb.no',
};

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AZURE_TENANT}`,
    redirectUri: AZURE_REDIRECT_URI,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
});

export async function signInWithAzure(): Promise<void> {
  localStorage.setItem('internalUser', 'true');
  return msalInstance.loginRedirect(loginRequest);
}

export async function logoutWithAzure(): Promise<void> {
  localStorage.removeItem('aad_token'); // TODO: Remove when old frontend is deleted
  return msalInstance.logoutRedirect();
}

export async function acquireTokenSilentWithAzure(
  forceRefresh: boolean = false,
): Promise<void> {
  const [account] = msalInstance.getAllAccounts();

  if (!account) return signInWithAzure();

  try {
    await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
      forceRefresh,
    });
  } catch (error) {
    // Catch interaction_required errors and call interactive method to resolve
    if (error instanceof InteractionRequiredAuthError) {
      try {
        await msalInstance.acquireTokenRedirect({
          ...loginRequest,
          account,
        });
      } catch (error_) {
        // eslint-disable-next-line no-console
        console.log(error_);
      }
    } else {
      localStorage.clear();
      location.reload();
    }
  }
}

export async function getAzureToken(): Promise<string | null> {
  const [account] = msalInstance.getAllAccounts();

  if (!account) return null;

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });
    return response.accessToken;
  } catch (error) {
    // Catch interaction_required errors and call interactive method to resolve
    if (error instanceof InteractionRequiredAuthError) {
      try {
        await msalInstance.acquireTokenRedirect({
          ...loginRequest,
          account,
        });
      } catch (error_) {
        // eslint-disable-next-line no-console
        console.log(error_);
      }
    } else {
      //TODO check for better ways
      localStorage.clear();
      location.reload();
    }

    return null;
  }
}

export function isSignedInWithAzure(): boolean {
  return msalInstance.getAllAccounts().length > 0;
}
