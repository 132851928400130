import { OpenAPIV3 } from 'openapi-types';
import { createContext } from 'react';
import DiscriminatorObject = OpenAPIV3.DiscriminatorObject;

export interface DiscriminatorContextProps {
  discriminator: DiscriminatorObject;
  discriminatorValue: string;
}

export const DiscriminatorContext = createContext<
  DiscriminatorContextProps | undefined
>(undefined);
