import { P } from '@dnb/eufemia';

import style from './index.module.css';

interface Props {
  name: string | undefined;
  required: boolean | undefined;
}

export default function NameAndRequired({ name, required }: Props) {
  return (
    <div>
      {name && (
        <P bottom="small">
          <strong>{name}</strong>
        </P>
      )}
      {required && <P className={style['required']}>required</P>}
    </div>
  );
}
