import type { OpenAPIV3 } from 'openapi-types';

import type { Dereferenced } from './types';

function isObject(candidate: unknown): candidate is Record<string, unknown> {
  return candidate !== null && typeof candidate === 'object';
}

function isReferenceObject(
  candidate: unknown,
): candidate is OpenAPIV3.ReferenceObject {
  return isObject(candidate) && typeof candidate['$ref'] === 'string';
}

function isValidProperty(
  property: string,
  target: unknown,
): target is Record<typeof property, unknown> {
  return (
    isObject(target) && Object.prototype.hasOwnProperty.call(target, property)
  );
}

/**
 * Resolve local references
 *
 * TODO: Figure out if ignore is more appropriate for invalid references
 * TODO: External references?
 */
export function resolve(reference: string, spec: OpenAPIV3.Document): unknown {
  const [first, ...segments] = reference
    .split('/')
    .map((segment) => segment.replace('~1', '/').replace('~0', '~'));

  if (first !== '#') {
    throw new Error('Only local JSON schema references are supported');
  }

  let current: unknown = spec;

  for (const segment of segments) {
    if (isValidProperty(segment, current)) {
      current = current[segment];
      continue;
    }

    throw new Error(`Unable to resolve reference ${reference}`);
  }

  if (isObject(current)) {
    current['referenceName'] = segments.at(-1);
  }

  return current;
}

export function dereference(
  spec: OpenAPIV3.Document,
): Dereferenced<OpenAPIV3.Document>;

export function dereference<T>(
  spec: OpenAPIV3.Document,
  references: Map<string, unknown>,
  current: T[],
  reference?: string,
): Dereferenced<T>[];

export function dereference<T>(
  spec: OpenAPIV3.Document,
  references: Map<string, unknown>,
  current: T,
  reference?: string,
): Dereferenced<T>;

/**
 * Resolves references recursively
 * Pointers are stored to handle circular references
 */

export function dereference(
  spec: OpenAPIV3.Document,
  references = new Map<string, unknown>(),
  current: unknown = spec,
  reference?: string,
): Dereferenced<unknown> {
  if (Array.isArray(current)) {
    const pointer: Dereferenced<unknown>[] = [];

    if (reference) {
      references.set(reference, pointer);
    }

    current.forEach((item) =>
      pointer.push(dereference(spec, references, item)),
    );

    return pointer;
  }

  if (isReferenceObject(current)) {
    const pointer = references.get(current.$ref);

    if (pointer) {
      return pointer;
    }

    const resolved = resolve(current.$ref, spec);
    const dereferenced = dereference(spec, references, resolved, current.$ref);

    if (isObject(dereferenced) && isObject(resolved)) {
      dereferenced['referenceName'] = resolved['referenceName'];
    }

    return dereferenced;
  }

  if (isObject(current)) {
    const pointer: Record<string, unknown> = {};

    if (reference) {
      references.set(reference, pointer);
    }

    Object.entries(current).forEach(([key, value]) => {
      pointer[key] = dereference(spec, references, value);
    });

    return pointer;
  }

  return current;
}
