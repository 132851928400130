import type { DereferencedParameterObject } from '@/pages/api-documentation/constants/types';

export function mergeParameters(
  lower?: DereferencedParameterObject[],
  higher?: DereferencedParameterObject[],
): DereferencedParameterObject[] {
  const parametersByKey: Record<string, DereferencedParameterObject> = {};

  const parameterKey = (parameter: DereferencedParameterObject) =>
    [parameter.in, parameter.name].join(':');

  higher?.forEach(
    (parameter) => (parametersByKey[parameterKey(parameter)] = parameter),
  );

  // Lower parameters should overwrite higher parameters with the same name
  lower?.forEach(
    (parameter) => (parametersByKey[parameterKey(parameter)] = parameter),
  );

  return Object.values(parametersByKey);
}
