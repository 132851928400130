/* eslint-disable max-lines */
// eslint-disable-next-line simple-import-sort/imports
import {
  Accordion,
  Anchor,
  Blockquote,
  Button,
  Code,
  Flex,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Hr,
  Icon,
  Li,
  Link,
  Ol,
  P,
  Space,
  Table,
  Tabs,
  Ul,
} from '@dnb/eufemia';

import {
  InfoIcon,
  MarketingIcon,
  WarnIcon,
} from '@dnb/eufemia/components/FormStatus';

import ExclamationCircle from '../../illustrations/ExclamationCircle.svg';

import { lightbulb_medium as LightBulb } from '@dnb/eufemia/icons';

import type { ContainerDirective } from 'mdast-util-directive';
import type { ReactMarkdownProps } from 'react-markdown/lib/ast-to-react';

import directive from 'remark-directive';
import { visit } from 'unist-util-visit';
import remarkGemoji from 'remark-gemoji';

import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-python';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './index.styles.css';
import slugify from 'slugify';
import {
  AccordionSection,
  Alert,
  Card,
  CodeWrapper,
  TabsSection,
} from '@/components/Markdown/markdown.styles';
import type { ButtonVariant } from '@dnb/eufemia/components/button';
import type { Sizes } from '@dnb/eufemia/components/flex/Item';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import type { SpaceType } from '@dnb/eufemia/components/space/types';
import CopyCode from '@/components/Markdown/CopyButton';

interface MarkdownProps {
  children: string;
}
interface Props {
  children: ReactNode | string;
  node?: {
    data?: {
      meta?: string;
    };
  };
  inline?: boolean;
  className?: string;
}

import MermaidDiagram from '@portals/shared-frontend/components/MermaidDiagram';
import type { Parent as DirectiveLabel } from 'ts-mdast';

type DirectiveContent = ContainerDirective['children'];

export type FlexProps = ComponentPropsWithoutRef<'flex'> &
  ReactMarkdownProps & {
    grid?: number;
  };
export type InfoProps = ComponentPropsWithoutRef<'info'> & ReactMarkdownProps;

function parseDirective(directive: ContainerDirective): {
  directiveLabel?: DirectiveLabel;
  contentNodes: DirectiveContent;
} {
  const hasDirectiveLabel = Boolean(
    directive.children?.[0]?.data &&
      hasOwnProperty(directive.children?.[0]?.data, 'directiveLabel') &&
      directive.children?.[0]?.data?.directiveLabel === true,
  );

  if (hasDirectiveLabel) {
    const [directiveLabel, ...contentNodes] = directive.children;
    return { directiveLabel: directiveLabel as DirectiveLabel, contentNodes };
  }
  return { directiveLabel: undefined, contentNodes: directive.children };
}

function getTitle(directiveLabel: DirectiveLabel): string | undefined {
  const isTextOnlyTitle =
    directiveLabel?.children?.length === 1 &&
    directiveLabel?.children?.[0]?.type === 'text';

  return isTextOnlyTitle
    ? // @ts-expect-error: todo type
      (directiveLabel?.children?.[0].value as string)
    : undefined;
}

function reactMarkdownRemarkDirective() {
  return (tree: any): void => {
    visit(
      tree,
      ['textDirective', 'leafDirective', 'containerDirective'],
      (node: any) => {
        if (node.type === 'containerDirective') {
          const directive = node as ContainerDirective;
          const { directiveLabel, contentNodes } = parseDirective(directive);
          const textOnlyTitle =
            directive.attributes && directive.attributes['title'] !== undefined
              ? directive.attributes['title']
              : directiveLabel
                ? getTitle(directiveLabel)
                : undefined;

          directive.data = {
            hName: node.name,
            hProperties: {
              ...(textOnlyTitle && { title: textOnlyTitle }),
              ...node.attributes,
              type: directive.name,
              identifier: 'footnoteDefinition',
            },
            ...node.data,
          };
          directive.children = contentNodes;

          if (directiveLabel && !textOnlyTitle) {
            const complexTitleNode = {
              type: 'mdxAdmonitionTitle',
              data: {
                type: 'mdxAdmonitionTitle',
                hProperties: {},
              },
              children: directiveLabel.children,
            };

            // @ts-expect-error: invented node type
            directive.children.unshift(complexTitleNode);
          }

          return directive;
        }

        node.data = {
          hName: node.name,
          hProperties: node.attributes,
          ...node.data,
        };

        return node;
      },
    );
  };
}

const codeBlockTitleRegex = /title=(?<quote>["'])(?<title>.*?)\1/;
function parseCodeBlockTitle(metastring?: string): string {
  return metastring?.match(codeBlockTitleRegex)?.groups!['title'] ?? '';
}

export default function Markdown({ children }: MarkdownProps): JSX.Element {
  const CodeSection = ({ children, ...props }: Props) => {
    if (
      Object.keys(props).includes('className') &&
      props['className'] === 'language-mermaid'
    ) {
      return <MermaidDiagram content={children} />;
    }
    if (!props?.inline) {
      const codeTitle =
        props.node?.data?.meta && parseCodeBlockTitle(props.node?.data.meta);
      const lang = props['className']
        ? (props['className'] as string).replace('language-', '')
        : 'txt';
      let prismCode;

      try {
        prismCode = Prism.highlight(
          children?.toString() || '',
          Prism.languages[lang],
          lang,
        );
      } catch {
        prismCode = Prism.highlight(
          children?.toString() || '',
          Prism.languages['txt'],
          'txt',
        );
      }

      return (
        <CodeWrapper>
          {codeTitle && <div className="title">{codeTitle}</div>}
          <pre
            className={`dnb-pre prism-code language-${lang}${
              codeTitle ? ' has-title' : ''
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: prismCode }} />
          </pre>
          <CopyCode code={children?.toString() || ''} />
        </CodeWrapper>
      );
    }

    return <Code>{children}</Code>;
  };

  return (
    <ReactMarkdown
      components={{
        hr: () => <Hr />,
        flex: ({ ...props }: FlexProps) => (
          <Flex.Container>
            {props['children'].map((child: any, i) => {
              const title = child.props.title;
              const data = child.props.children;
              const gridSize = ((props.grid && 12 / props.grid) ||
                12 / props['children'].length) as Sizes;

              return (
                <Flex.Item
                  key={`flex-item-${i}`}
                  size={{
                    medium: gridSize,
                    small: 12,
                  }}
                  title={title}
                >
                  <Card type={child.props.card}>
                    {(Boolean(child.props.title && child.props.url) && (
                      <H4 bottom="x-small">
                        <Anchor
                          href={child.props.url}
                          icon="arrow_right"
                          iconPosition="right"
                        >
                          {child.props.title}
                        </Anchor>
                      </H4>
                    )) ||
                      (child.props.title && <H4>{child.props.title}</H4>)}
                    {data}
                  </Card>
                </Flex.Item>
              );
            })}
          </Flex.Container>
        ),
        button: ({ ...props }) =>
          (hasOwnProperty(props, 'url') && (
            <Button
              on_click={() => {
                typeof props.url === 'string'
                  ? location.assign(props.url)
                  : null;
              }}
              variant={
                (props.type && (props.type as ButtonVariant)) || 'primary'
              }
            >
              {props.children}
            </Button>
          )) || (
            <Button
              variant={
                (props.type && (props.type as ButtonVariant)) || 'primary'
              }
            >
              {props.children}
            </Button>
          ),
        br: () => <br />,
        info: ({ ...props }: InfoProps) => (
          <Alert className="info">
            <Icon icon={LightBulb} />
            <div className="text">
              {props.title && (
                <P medium size="small">
                  {props.title}
                </P>
              )}
              {props['children']}
            </div>
          </Alert>
        ),
        note: ({ ...props }) => (
          <Alert className="note">
            <MarketingIcon />
            <div className="text">
              {props['title'] && (
                <P medium size="small">
                  {props['title']}
                </P>
              )}
              {props['children']}
            </div>
          </Alert>
        ),
        tip: ({ ...props }) => (
          <Alert className="tip">
            <InfoIcon />
            <div className="text">
              {props['title'] && (
                <P medium size="small">
                  {props['title']}
                </P>
              )}
              {props['children']}
            </div>
          </Alert>
        ),
        warning: ({ ...props }) => (
          <Alert className="warning">
            <WarnIcon />
            <div className="text">
              {props['title'] && (
                <P medium size="small">
                  {props['title']}
                </P>
              )}
              {props['children']}
            </div>
          </Alert>
        ),
        danger: ({ ...props }) => (
          <Alert className="danger">
            <ExclamationCircle />
            <div className="text">
              {props['title'] && (
                <P medium size="small">
                  {props['title']}
                </P>
              )}
              {props['children']}
            </div>
          </Alert>
        ),
        h1: ({ ...props }) => (
          <H1
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H1>
        ),
        h2: ({ ...props }) => (
          <H2
            bottom="small"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
            top="medium"
          >
            {props.children}
          </H2>
        ),
        h3: ({ ...props }) => (
          <H3
            bottom="small"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
            top="medium"
          >
            {props.children}
          </H3>
        ),
        h4: ({ ...props }) => (
          <H4
            bottom="small"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
            top="medium"
          >
            {props.children}
          </H4>
        ),
        h5: ({ ...props }) => (
          <H5
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H5>
        ),
        h6: ({ ...props }) => (
          <H6
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H6>
        ),
        ul: ({ ...props }) => (
          <Ul bottom="medium" top="medium">
            {props.children}
          </Ul>
        ),
        ol: ({ ...props }) => (
          <Ol bottom="medium" top="medium">
            {props.children}
          </Ol>
        ),
        li: ({ ...props }) => (
          <Li bottom="medium" top="medium">
            {props.children}
          </Li>
        ),
        accordion: ({ ...props }) => {
          return (
            <AccordionSection className={props['contrast'] ? 'contrast' : ''}>
              <Accordion
                bottom="medium"
                expanded={props['expanded']}
                top="medium"
              >
                <Accordion.Header>{props['title']}</Accordion.Header>
                <Accordion.Content>{props['children']}</Accordion.Content>
              </Accordion>
            </AccordionSection>
          );
        },
        space: ({ ...props }) => (
          <Space
            top={((props['size'] && props['size']) || 'small') as SpaceType}
          />
        ),
        tabs: ({ ...props }) => {
          return (
            <TabsSection>
              <Tabs content_spacing="x-small" prerender>
                {props['children'].map((child: any, i) => {
                  const title = child.props.title;
                  const data = child.props.children;

                  return (
                    <Tabs.Content key={`tab-item-${i}`} title={title}>
                      {data}
                    </Tabs.Content>
                  );
                })}
              </Tabs>
            </TabsSection>
          );
        },
        blockquote: ({ ...props }) => (
          <Blockquote bottom="medium" top="medium">
            {props.children}
          </Blockquote>
        ),
        table: ({ ...props }) => (
          <Table bottom="medium" top="medium" {...props}>
            {props.children}
          </Table>
        ),
        a: ({ ...props }) => <Link {...props}>{props.children}</Link>,
        code: ({ ...props }) => (
          <CodeSection {...props}>{props.children}</CodeSection>
        ),
        p: ({ ...props }) => (
          <P bottom="small" {...props}>
            {props.children}
          </P>
        ),
      }}
      remarkPlugins={[
        directive,
        remarkGfm,
        reactMarkdownRemarkDirective,
        remarkGemoji,
      ]}
    >
      {children}
    </ReactMarkdown>
  );
}
