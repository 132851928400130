import { Button } from '@dnb/eufemia';
import {
  isIncluded,
  isObjectOrArraySchema,
  SchemaType,
} from '@portals/shared/common/open-api/openapi-helpers';
import { useContext, useState } from 'react';

import { DereferencedSchemaObject } from '@/pages/api-documentation/constants/types';
import SchemaItem from '@/pages/api-documentation/sections/reference/components/ReferenceView/components/Item/components/SchemaItem';
import SchemaItemDescription from '@/pages/api-documentation/sections/reference/components/ReferenceView/components/Item/components/SchemaItem/components/SchemaItemDescription';
import { DiscriminatorContext } from '@/pages/api-documentation/sections/reference/components/ReferenceView/components/Item/components/SchemaItem/context/DiscriminatorContext';

import style from './index.module.css';

interface Props {
  schema: DereferencedSchemaObject;
  schemaType: SchemaType;
  name?: string;
  required?: boolean;
}

export default function SingleSchemaView({
  schema,
  schemaType,
  name,
  required,
}: Props) {
  const [opened, setOpened] = useState(false);

  const discriminatorContext = useContext(DiscriminatorContext);

  return (
    <div>
      <SchemaItemDescription name={name} required={required} schema={schema} />
      {isObjectOrArraySchema(schema) && (
        <DiscriminatorContext.Provider
          // Discriminator context should only be forwarded if this schema is part of the discriminator schema options.
          value={
            discriminatorContext?.discriminatorValue === schema.referenceName
              ? discriminatorContext
              : undefined
          }
        >
          <Button
            icon={opened ? 'chevron_down' : 'chevron_right'}
            on_click={() => setOpened(!opened)}
            variant="tertiary"
          >
            {schema.type === 'object' ? 'Properties' : 'Items'}
          </Button>
          {opened &&
            (schema.type === 'object' ? (
              <ul className={style['schema-item-list']}>
                {schema.properties &&
                  Object.entries(schema.properties)
                    .filter(([, subSchema]) =>
                      // @ts-expect-error - somehow string is added as a possible type for subSchema
                      isIncluded(schemaType, subSchema),
                    )
                    .map(([name, subSchema]) => (
                      <li key={name}>
                        <SchemaItem
                          isSubSchema
                          name={name}
                          required={schema.required?.includes(name) ?? false}
                          // @ts-expect-error - somehow string is added as a possible type for subSchema
                          schema={subSchema}
                          schemaType={schemaType}
                        />
                      </li>
                    ))}
              </ul>
            ) : (
              schema.type === 'array' && (
                <SchemaItem schema={schema.items} schemaType={schemaType} />
              )
            ))}
        </DiscriminatorContext.Provider>
      )}
    </div>
  );
}
