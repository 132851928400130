import {
  isUnionSchema,
  resolveSchema,
  Schema,
  SchemaType,
} from '@portals/shared/common/open-api/openapi-helpers';
import classNames from 'classnames';

import SingleSchemaView from './components/SingleSchemaView';
import UnionSchemaView from './components/UnionSchemaView';

import * as Styled from './index.styles';

type SchemaItemProps = {
  schema: Schema;
  schemaType: SchemaType;
  name?: string;
  required?: boolean;
  isSubSchema?: boolean;
};

export default function SchemaItem({
  schema: unresolvedSchema,
  schemaType,
  name,
  required,
  isSubSchema,
}: SchemaItemProps) {
  const schema = isUnionSchema(unresolvedSchema)
    ? unresolvedSchema
    : resolveSchema(unresolvedSchema);

  return (
    <Styled.SchemaItem className={classNames({ 'sub-schema': isSubSchema })}>
      {isUnionSchema(schema) ? (
        <UnionSchemaView
          name={name}
          required={required}
          schema={schema}
          schemaType={schemaType}
        />
      ) : (
        <SingleSchemaView
          name={name}
          required={required}
          schema={schema}
          schemaType={schemaType}
        />
      )}
    </Styled.SchemaItem>
  );
}
