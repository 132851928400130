import styled from '@emotion/styled';

export const SchemaItem = styled.div`
  padding: var(--spacing-small);
  border: 1px solid var(--color-black-8);
  background-color: var(--color-white);

  &.sub-schema {
    border: none;
    background-color: inherit;
  }
`;
