import { Dropdown } from '@dnb/eufemia';
import { OpenAPIV3 } from 'openapi-types';
import { useState } from 'react';

import Example from '../Example';
import ExampleObject = OpenAPIV3.ExampleObject;

interface Props {
  examples: { [media: string]: ExampleObject };
  contentType: string;
}

export default function NamedExamples({ examples, contentType }: Props) {
  const exampleNames = Object.keys(examples);
  const [selectedExampleName, setSelectedExampleName] = useState(
    exampleNames[0],
  );

  const selectedExample = examples[selectedExampleName].value;

  return (
    <div>
      <Dropdown
        bottom="small"
        data={exampleNames}
        // @ts-ignore property 'data' does in fact exist
        onChange={({ data }) => setSelectedExampleName(data)}
        top="small"
        value={selectedExampleName}
      />
      <Example contentType={contentType} example={selectedExample} />
    </div>
  );
}
