import { Flex, ProgressIndicator } from '@dnb/eufemia';
import { ContentDto } from '@portals/shared/portal/ContentDto';
import useSWR from 'swr';

import Guide from '@/components/Guide';
import HeroPage from '@/components/HeroPage';

export default function GettingStartedApiProviders() {
  const { data: content, isValidating: loading } = useSWR<ContentDto[]>(
    `/content/tpp/${DEVELOPER_PORTAL_TEAM_ID}/api-providers`,
  );

  return (
    <HeroPage
      heroIllustration={require('@/illustrations/DNB_Supergraphics_front_page.avif?url')}
      noContainer
      noTopMargin
      subtitle="Follow these steps to publish your APIs"
      title="Getting started for API providers"
    >
      {content && !loading ? (
        <Guide guide={content[0].blob.content} />
      ) : (
        <Flex.Horizontal justify="center">
          <ProgressIndicator />
        </Flex.Horizontal>
      )}
    </HeroPage>
  );
}
