import { Button } from '@dnb/eufemia';
import { useSafeState } from '@portals/shared-frontend/hooks';
import { type ComponentPropsWithoutRef, useCallback, useRef } from 'react';

/**
 * Button that copies to clipboard and displays alternate text for a given time.
 * Forwards all props to underlying button component.
 */

export type CopyButtonProps = ComponentPropsWithoutRef<typeof Button> & {
  toCopy?: string;
  alternateText?: string;
  alternateTextTimeout?: number;
};

export default function CopyButton({
  toCopy = '',
  alternateText = 'Copied',
  alternateTextTimeout = 2000,
  on_click,
  text,
  children,
  ...props
}: CopyButtonProps): JSX.Element {
  const [showAlternateText, setShowAlternateText] = useSafeState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const handleClick = useCallback(
    (...args: never[]) => {
      navigator.clipboard.writeText(toCopy);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      setTimeout(() => setShowAlternateText(false), alternateTextTimeout);
      setShowAlternateText(true);

      if (typeof on_click === 'function') {
        on_click(...args);
      }
    },
    [toCopy, alternateTextTimeout, setShowAlternateText, on_click],
  );

  return (
    <Button on_click={handleClick} {...props}>
      {showAlternateText ? alternateText : (text ?? children)}
    </Button>
  );
}
