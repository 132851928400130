import { deepCopy } from '../utils';
import { Dereferenced } from './types';
import DiscriminatorObject = OpenAPIV3.DiscriminatorObject;
import { OpenAPIV3 } from 'openapi-types';
import SchemaObject = OpenAPIV3.SchemaObject;

type DereferencedSchemaObject = Dereferenced<SchemaObject>;

interface NonDiscriminatorUnionSchema {
  type: 'anyOf' | 'oneOf';
  schemas: Schema[];
}

export interface DiscriminatorUnionSchema {
  type: 'anyOf' | 'oneOf';
  discriminator: OpenAPIV3.DiscriminatorObject;
  schemas: DereferencedSchemaObject[];
}

export type UnionSchema =
  | NonDiscriminatorUnionSchema
  | DiscriminatorUnionSchema;

export type Schema = DereferencedSchemaObject | UnionSchema;

export function resolveSchema(schema: DereferencedSchemaObject): Schema {
  let updatedSchema: Schema = deepCopy(schema);

  if (updatedSchema.allOf) {
    const allOf = deepCopy(updatedSchema.allOf);
    delete updatedSchema.allOf;

    const subSchemas = allOf.map((subSchema) => resolveSchema(subSchema));
    updatedSchema = mergeSchemas(updatedSchema, subSchemas);
  }

  // anyOf or oneOf was present in allOf, returning these unions
  if (isUnionSchema(updatedSchema)) return updatedSchema;

  const unionType = updatedSchema.anyOf
    ? 'anyOf'
    : updatedSchema.oneOf && 'oneOf';
  if (!unionType) return updatedSchema;

  if (updatedSchema[unionType]) {
    // @ts-ignore updatedSchema[unionType] is not undefined
    const subSchemas = updatedSchema[unionType].map((subSchema) =>
      resolveSchema(deepCopy(subSchema)),
    );

    // Delete anyOf and allOf so that they don't get included in the schema merge
    delete updatedSchema.anyOf;
    delete updatedSchema.oneOf;

    // Delete discriminator from schema, as it should not be merged into the subSchemas
    const discriminator = deepCopy(updatedSchema.discriminator);
    delete updatedSchema.discriminator;

    const merged = subSchemas.map((subSchema) =>
      mergeSchemas(updatedSchema, [subSchema], true),
    );

    if (discriminator) {
      const discriminatorSchemas = merged.filter((schema) =>
        isValidDiscriminatorSchema(discriminator, schema),
      );
      return {
        type: unionType,
        discriminator: discriminator,
        schemas: discriminatorSchemas,
      };
    }

    return {
      type: unionType,
      schemas: merged,
    };
  }

  return updatedSchema;
}

/**
 * Filters out schemas that should not be included in the discriminator set.
 *
 * Filter checks:
 * 1. Schema cannot be UnionSchema (union schemas do not have properties to match the discriminator)
 * 2. Schema cannot be inline-schema (must contain referenceName)
 * 3. Schema must have a property that matches the discriminator
 */
function isValidDiscriminatorSchema(
  discriminator: DiscriminatorObject,
  schema: Schema,
): schema is DereferencedSchemaObject {
  if (isUnionSchema(schema)) return false;
  if (!schema.referenceName || !schema.properties) return false;
  return Object.hasOwn(schema.properties, discriminator.propertyName);
}

function mergeSchemas(
  schema: DereferencedSchemaObject,
  subSchemas: Schema[],
  parentIsUnion: boolean = false,
): Schema {
  if (subSchemas.length === 0) return schema;

  // If there exists a union schema only one can be part of the merge result, the others must be disregarded.
  const unionSchema = subSchemas.find(isUnionSchema);
  if (unionSchema) {
    const nonUnionSubSchemas = subSchemas.filter(
      (subSchema): subSchema is DereferencedSchemaObject =>
        !isUnionSchema(subSchema),
    );
    const mergedSchema = nonUnionSubSchemas.reduce(
      (mergedSchema, subSchema) =>
        mergeSchema(mergedSchema, subSchema, parentIsUnion),
      schema,
    );

    return {
      type: unionSchema.type,
      schemas: unionSchema.schemas.map((unionSubSchema) =>
        mergeSchemas(mergedSchema, [unionSubSchema], true),
      ),
    };
  } else {
    const nonUnionSubSchemas = subSchemas as DereferencedSchemaObject[];
    return nonUnionSubSchemas.reduce(
      (mergedSchema, subSchema) =>
        mergeSchema(mergedSchema, subSchema, parentIsUnion),
      schema,
    );
  }
}

function mergeSchema(
  schema1: DereferencedSchemaObject,
  schema2: DereferencedSchemaObject,
  parentIsUnion: boolean,
): DereferencedSchemaObject {
  const type = schema1.type || schema2.type;

  const schema1Copy = deepCopy(schema1);
  const schema2Copy = deepCopy(schema2);

  if (parentIsUnion) {
    // If parent is union (contains oneOf or anyOf) then the merge should use the child's referenceName
    delete schema1Copy.referenceName;
  } else {
    // If the parent is not a union (contains allOf) then the merge should use the parent's referenceName
    delete schema2Copy.referenceName;
  }

  if (type === 'object') {
    return mergeObjectSchemas(schema1Copy, schema2Copy);
  }

  // If there are overlapping properties then the property of `schema1` will have precedence.
  return { ...schema2Copy, ...schema1Copy };
}

/**
 * Takes in two schemas and returns a copy of the merged schemas without changing any of the original schemas.
 * If there are overlapping properties then the property of `schema1` will have precedence.
 */
function mergeObjectSchemas(
  schema1Copy: DereferencedSchemaObject,
  schema2Copy: DereferencedSchemaObject,
): DereferencedSchemaObject {
  // Overlapping properties from schema1 will take precedence
  const mergedSchema = { ...schema2Copy, ...schema1Copy };

  // Complex properties to support:
  //   default?: any;
  //   additionalProperties?: boolean | SchemaObject;
  //   required?: string[];
  //   enum?: any[];
  //   not?: SchemaObject;
  //   discriminator?: DiscriminatorObject;
  //   xml?: XMLObject;
  //   externalDocs?: ExternalDocumentationObject;
  //   example?: any;

  if (schema2Copy.properties) {
    Object.entries(schema2Copy.properties).forEach(([key, value]) => {
      if (mergedSchema.properties && !mergedSchema.properties[key]) {
        // @ts-ignore TODO temp
        mergedSchema.properties[key] = value;
      }
    });
  }

  return mergedSchema;
}

export function isObjectOrArraySchema(
  schema: Schema,
): schema is DereferencedSchemaObject {
  return schema.type === 'object' || schema.type === 'array';
}

export function isUnionSchema(schema: Schema): schema is UnionSchema {
  return Object.hasOwn(schema, 'schemas');
}

export function isDiscriminatorUnionSchema(
  schema: UnionSchema,
): schema is DiscriminatorUnionSchema {
  return Object.hasOwn(schema, 'discriminator');
}

export function isDereferencedSchema(
  schema: Schema,
): schema is DereferencedSchemaObject {
  return !Object.hasOwn(schema, 'schemas');
}

export type SchemaType = 'request' | 'response';

/**
 * Filters out properties that should only be included in either request or response
 */
export function isIncluded(
  schemaType: SchemaType,
  subSchema: DereferencedSchemaObject,
): boolean {
  if (subSchema.writeOnly === true) {
    return schemaType === 'request';
  }
  if (subSchema.readOnly === true) {
    return schemaType === 'response';
  }
  return true;
}

export const schemaTypeDisplayName: Record<'anyOf' | 'oneOf', string> = {
  anyOf: 'any of',
  oneOf: 'one of',
};

export function getSchemaName(schema: Schema): string {
  if (isUnionSchema(schema)) {
    return schemaTypeDisplayName[schema.type];
  } else {
    return schema.referenceName ?? schema.type ?? 'unknown';
  }
}
