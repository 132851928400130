import { Div, Flex, Grid, H3, Link, Skeleton } from '@dnb/eufemia';
import styled from '@emotion/styled';
import { StatusDto } from '@portals/shared/portal/StatusDto';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import style from './index.module.css';

const statusTitle: Record<number, string> = {
  0: 'All APIs are operational',
  1: 'Some APIs may have instabilities',
  2: 'Some APIs have reduced service',
};

const ApiStatusWidgetWrapper = styled(Flex.Horizontal)`
  height: 100%;
`;

export default function ApiStatusWidget() {
  const { data: status, isValidating: isLoading } =
    useSWR<StatusDto[]>('/status');

  const highestSeverity =
    status && status.length > 0
      ? Math.max(...status.map(({ severity }) => severity))
      : 0;

  return (
    <Skeleton show={isLoading}>
      <Div className={style['apiStatusWidget']}>
        <Grid.Container columnGap="small" columns={10} rowGap="small">
          <Grid.Item span={[1, 1]}>
            <ApiStatusWidgetWrapper align="center" justify="center">
              <div
                className={classNames(
                  style['apiStatusBadge'],
                  style[`apiStatusBadge-severity-${highestSeverity}`],
                )}
              />
            </ApiStatusWidgetWrapper>
          </Grid.Item>
          <Grid.Item span={[2, 10]}>
            <H3 bottom="0.25rem" top="0.25rem">
              {statusTitle[highestSeverity]}
            </H3>
          </Grid.Item>
          <Grid.Item span={[2, 9]}>
            <Link element={RouterLink} to="/status">
              API status overview
            </Link>
          </Grid.Item>
        </Grid.Container>
      </Div>
    </Skeleton>
  );
}
