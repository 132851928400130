import { Paragraph } from '@dnb/eufemia';
import { isObject } from '@portals/shared-frontend/utils';
import Prism from 'prismjs';
import { useEffect } from 'react';

interface Props {
  example: unknown;
  contentType: string;
}

/**
 * Add support for content types as needed/requested.
 */
function getPrismLanguage(contentType: string): string {
  switch (contentType) {
    case 'application/json': {
      return 'json';
    }
    case 'application/xml': {
      return 'xml';
    }
    case 'application/x-yaml': {
      return 'yaml';
    }
    default: {
      return 'txt';
    }
  }
}

export default function Example({ example, contentType }: Props) {
  useEffect(() => {
    Prism.highlightAll();
  }, [example, contentType]);

  if (!example) {
    return (
      <Paragraph top="small">
        <i>Example not provided.</i>
      </Paragraph>
    );
  }

  const prismLanguage = getPrismLanguage(contentType);
  const exampleData = isObject(example)
    ? JSON.stringify(example, null, '\t')
    : example;

  return (
    <pre className="dnb-pre">
      <code className={`language-${prismLanguage}`}>{exampleData}</code>
    </pre>
  );
}
