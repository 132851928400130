import { Tabs } from '@dnb/eufemia';
import {
  getSchemaName,
  isDiscriminatorUnionSchema,
  Schema,
  SchemaType,
  schemaTypeDisplayName,
  UnionSchema,
} from '@portals/shared/common/open-api/openapi-helpers';
import { useState } from 'react';

import SchemaItem from '@/pages/api-documentation/sections/reference/components/ReferenceView/components/Item/components/SchemaItem';
import NameAndRequired from '@/pages/api-documentation/sections/reference/components/ReferenceView/components/Item/components/SchemaItem/components/NameAndRequired';
import {
  DiscriminatorContext,
  DiscriminatorContextProps,
} from '@/pages/api-documentation/sections/reference/components/ReferenceView/components/Item/components/SchemaItem/context/DiscriminatorContext';

import style from './index.module.css';

interface Props {
  schema: UnionSchema;
  schemaType: SchemaType;
  name: string | undefined;
  required: boolean | undefined;
}

function getUnionSchemas(schema: UnionSchema): Schema[] {
  if (isDiscriminatorUnionSchema(schema)) {
    return schema.schemas.filter(
      (subSchema) => subSchema.referenceName != undefined,
    );
  }
  return schema.schemas;
}

export default function UnionSchemaView({
  schema,
  schemaType,
  name,
  required,
}: Props) {
  const schemas = getUnionSchemas(schema);
  const [selectedKey, setSelectedKey] = useState<number>(0);

  const discriminatorContext: DiscriminatorContextProps | undefined =
    isDiscriminatorUnionSchema(schema)
      ? {
          discriminator: schema.discriminator,
          discriminatorValue: getSchemaName(schemas[selectedKey]),
        }
      : undefined;

  return (
    <div>
      <NameAndRequired name={name} required={required} />
      <p className={style['schema-item-type']}>
        {schemaTypeDisplayName[schema.type]}
      </p>
      <DiscriminatorContext.Provider value={discriminatorContext}>
        <Tabs
          content_spacing={false}
          data={schemas.map((_schema, index) => ({
            title: getSchemaName(_schema),
            key: index.toString(),
            content: <SchemaItem schema={_schema} schemaType={schemaType} />,
          }))}
          no_border
          on_change={({ selected_key }) => setSelectedKey(selected_key)}
          selected_key={selectedKey}
        />
      </DiscriminatorContext.Provider>
    </div>
  );
}
