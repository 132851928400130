import { ApiDto } from '@portals/shared/portal/ApiDto';

import {
  EXTERNAL_CLASSIFICATIONS,
  INTERNAL_CLASSIFICATIONS,
} from '../../content';

export enum Categories {
  All = 'All',
  InternalApis = 'Internal APIs',
  Commercial = 'Commercial APIs',
  RegulatoryApis = 'Regulatory APIs',
}

export const apiByCategory = (api: ApiDto, category?: Categories | null) => {
  switch (category) {
    case Categories.InternalApis: {
      return INTERNAL_CLASSIFICATIONS.has(api.classification);
    }
    case Categories.Commercial: {
      return (
        !api.tags.includes('PSD2') &&
        EXTERNAL_CLASSIFICATIONS.has(api.classification)
      );
    }
    case Categories.RegulatoryApis: {
      return api.tags.includes('PSD2');
    }
    default: {
      return true;
    }
  }
};
