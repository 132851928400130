import { H3, H4 } from '@dnb/eufemia';
import styled from '@emotion/styled';

import { mq } from './Api.global.styles';

export const Page = styled.div`
  grid-template-columns: auto;

  ${mq['large']} {
    grid-template-columns: 22em auto;
  }
  ${mq['x-large']} {
    grid-template-columns: 0.95fr 960px 1fr;
  }

  display: grid;
  background-color: ${(props) => props?.theme?.colors.backgroundDocumentation};

  button.mobile-menu {
    position: fixed;
    bottom: var(--spacing-x-large);
    left: var(--spacing-large);
    width: var(--spacing-x-large);
    height: var(--spacing-x-large);
    border-radius: var(--spacing-x-large);
    border: 1px solid var(--color-sea-green);
    background-color: var(--color-white);
    z-index: 100002;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    cursor: pointer;

    ${mq['large']} {
      display: none;
    }

    &:hover {
      background-color: var(--color-mint-green-25);
    }

    &.active {
      span {
        &:first-child {
          transform-origin: top left;
          transform: rotate(45deg) translate(0.1875rem, -0.1875rem);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform-origin: bottom left;
          transform: rotate(-45deg) translate(0.1875rem, 0.1875rem);
        }
      }
    }

    span {
      display: block;
      width: 1.5rem;
      height: 0.125rem;
      background: var(--color-sea-green);
      margin: 0.1875rem;

      &:first-child {
        transform-origin: top left;
        transition: transform 0.2s ease-in-out;
      }

      &:nth-child(2) {
        transition: opacity 0.2s ease-in-out;
      }

      &:nth-child(3) {
        transform-origin: bottom left;
        transition: transform 0.2s ease-in-out;
      }
    }
  }

  .__left {
    background-color: ${(props) => props?.theme?.colors.backgroundMenu};
    border-right: 1px solid var(--color-black-8);
    position: static;
    top: 0;
    padding-top: var(--spacing-large);
    height: auto;
    display: none;

    &.active {
      display: inline-block;
      width: 100%;
      position: fixed;
      z-index: 100001;
      top: 0;
      left: 0;

      .sticker {
        float: left;
      }

      li.active {
        background-color: inherit;
      }
    }

    ${mq['large']} {
      display: inline-block;
    }

    .sticker {
      height: 100vh;
      width: 100%;
      position: sticky;
      top: 5rem;
      overflow-y: hidden;
      padding-bottom: 1.5rem;
    }

    .scroll-content {
      overflow-y: auto;
      height: 96%;
      width: 100%;
      padding-bottom: var(--spacing-xx-large);
      clear: both;
      float: right;
    }

    .search-bar {
      width: 22rem;
      float: left;
      padding-left: var(--spacing-large);
      padding-bottom: var(--spacing-small);

      ${mq['large']} {
        float: right;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      padding-bottom: 5em;
      overflow-y: auto;
      min-width: 22rem;
      width: 22rem;
      float: left;
      clear: both;

      ${mq['large']} {
        float: right;
      }

      &.introduction {
        margin-bottom: var(--spacing-small);
        padding-bottom: 0;
      }

      li {
        position: relative;
        color: ${(props) => props?.theme?.colors.menuLinks};

        list-style: none;
        font-size: var(--font-size-small);

        a {
          cursor: pointer;
          text-decoration: none;
          display: flex;
          color: ${(props) => props?.theme?.colors.menuLinks};
          align-items: center;
          padding: var(--spacing-small) var(--spacing-large);
          &.dnb-anchor {
            padding: 0;
            color: inherit;
          }
        }

        span {
          display: none;
          position: absolute;
          right: 20px;
          top: 20px;

          ${mq['large']} {
            display: block;
          }
        }

        &.active {
          background-color: ${(props) => props?.theme?.colors.menuLinksActive};

          a {
            font-weight: var(--font-size-medium);
          }
        }

        &:hover {
          background-color: ${(props) => props?.theme?.colors.menuLinksHover};
        }

        &.sub {
          padding-left: var(--spacing-large);
          .operator {
          }
        }

        a > div:last-child {
          width: 70%;
        }

        &.header {
          display: block;
          padding: var(--spacing-small) var(--spacing-large);
          text-transform: uppercase;
          color: ${(props) => props?.theme?.colors.menuSecondary};
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  .content {
    width: auto;
    margin: 0 var(--spacing-medium);
    max-width: 960px;
    padding-bottom: calc(var(--spacing-xx-large) + var(--spacing-xx-large));
    padding-right: 1rem;
    scroll-behavior: auto;

    ${mq['medium']} {
      margin: 0 var(--spacing-large);
    }

    .code {
      width: 100%;
      display: inline-grid;
    }

    .download {
      margin-top: var(--spacing-x-large);
      margin-bottom: var(--spacing-medium);

      span {
        margin-right: var(--spacing-xx-small);
      }
      a {
        cursor: pointer;
      }
    }
    h1 {
      font-size: var(--font-size-x-large);
      line-height: var(--line-height-basis);
      margin: var(--spacing-x-large) 0 var(--spacing-large) 0;
      font-weight: var(--font-weight-medium);
    }
    #introduction {
      p {
        margin-top: var(--spacing-large);
        margin-bottom: 0;
        font-size: var(--font-size-basis);
      }
    }
  }
`;

export const H3S = styled(H3)`
  font-size: var(--font-size-large);
  line-height: var(--line-height-lead);
  margin: var(--spacing-large) 0 var(--spacing-small) 0 !important;
`;

export const H4S = styled(H4)`
  font-size: var(--font-size-medium);
  text-transform: capitalize;
  line-height: var(--line-height-basis);
  margin: var(--spacing-large) 0 var(--spacing-small) 0 !important;
`;
