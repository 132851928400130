import { Div, Flex, H1, P } from '@dnb/eufemia';
import { useMedia } from '@dnb/eufemia/shared';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import style from './HeroHeader.module.css';

type HeroHeaderProps = PropsWithChildren<{
  image: string;
  altText: string;
  heroText: string;
  heroSubtitle?: string;
}>;

type heroContainerWrapperProps = {
  backgroundImage: string;
  isSmall: boolean;
};

const HeroContainerWrapper = styled.div<heroContainerWrapperProps>`
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  object-fit: contain;
  background-size: cover;
  background-position: cover;
  overflow: hidden;
  height:   ${(props) => (props.isSmall ? css`15rem` : css`12rem`)}
  top: 0;
  ${(props) =>
    props.backgroundImage &&
    css`
      background-image: url(${props.backgroundImage});
    `}
  left: 0;
`;

const HeroHeader = ({
  image,
  heroText,
  heroSubtitle,
  children,
}: HeroHeaderProps): JSX.Element => {
  const { isSmall } = useMedia();

  const noChildrenOrSmallScreen = children || isSmall;

  return (
    <HeroContainerWrapper
      backgroundImage={image}
      id="HeroHeader"
      isSmall={isSmall}
    >
      <Div
        className={
          noChildrenOrSmallScreen
            ? style['HeroContent']
            : classNames(style['HeroContentNoChildren'], style['HeroContent'])
        }
      >
        <Flex.Horizontal
          align="center"
          gap={false}
          justify={noChildrenOrSmallScreen ? 'space-evenly' : 'flex-start'}
        >
          <Div className={style['HeroText']}>
            <H1 size="x-large">{heroText}</H1>
            {heroSubtitle && <P modifier="medium">{heroSubtitle}</P>}
          </Div>
          {children && <div>{children}</div>}
        </Flex.Horizontal>
      </Div>
    </HeroContainerWrapper>
  );
};

export default HeroHeader;
