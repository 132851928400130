import { OpenAPIV3 } from 'openapi-types';

import { stringifyRemovingCyclicFields } from '../utils';
import {
  isDereferencedSchema,
  isIncluded,
  resolveSchema,
  Schema,
  SchemaType,
} from './openapi-helpers';
import { Dereferenced } from './types';

type DereferencedSchemaObject = Dereferenced<OpenAPIV3.SchemaObject>;

/**
 * @return The first subschema in the UnionSchema that is not a UnionSchema or null if none was found.
 */
function getFirstDereferencedSchema(
  schema: Schema,
): DereferencedSchemaObject | null {
  if (isDereferencedSchema(schema)) return schema;
  return schema.schemas[0]
    ? getFirstDereferencedSchema(schema.schemas[0])
    : null;
}

/**
 * Utility function to return a schema example, taking into account anyOf, oneOf and allOf.
 * When there are multiple possible examples, they have the following precedence of priority:
 * 1. If example field is present, this is returned
 * 2. if default field is present, this is returned
 * 3. if [createExample] is set to true, the schema object is inspected and an example is generated from that.
 *
 * @param unresolvedSchema
 * @param schemaType
 * @param createExample Will create an example by inspecting the schema if no other example is provided.
 * @param seen
 */
// eslint-disable-next-line max-lines-per-function
export function parseExample(
  unresolvedSchema: DereferencedSchemaObject,
  schemaType: SchemaType,
  createExample: boolean,
  seen = new Set<string>(),
): unknown {
  const resolvedSchema = resolveSchema(unresolvedSchema);

  // The example will just return one possible combination
  const schema = getFirstDereferencedSchema(resolvedSchema);
  if (!schema) return null;

  if (schema.example) return schema.example;
  if (schema.default) return schema.default;

  if (!createExample) return null;

  switch (schema.type) {
    case 'number':
    case 'integer': {
      return 0;
    }
    case 'string': {
      if (schema.enum && schema.enum.length > 0) {
        return schema.enum[0];
      }
      return 'string';
    }
    case 'boolean': {
      return true;
    }
    case 'object': {
      const stringifiedSchema = stringifyRemovingCyclicFields(schema);
      if (seen.has(stringifiedSchema)) {
        // cyclic reference, return empty object
        return {};
      }
      seen.add(stringifiedSchema);

      const data: { [p: string]: unknown } = {};

      if (schema.properties) {
        Object.keys(schema.properties).forEach((subItem) => {
          if (schema.properties && schema.properties[subItem]) {
            const subSchema = schema.properties[subItem];
            if (subSchema && isIncluded(schemaType, subSchema)) {
              data[subItem] = parseExample(subSchema, schemaType, true, seen);
            }
          }
        });
      }

      if (schema.additionalProperties) {
        const addProps =
          schema.additionalProperties as DereferencedSchemaObject;

        if (addProps.properties) {
          Object.keys(addProps.properties).forEach((subItem) => {
            if (addProps.properties && addProps.properties[subItem]) {
              const subSchema = addProps.properties[subItem];
              if (subSchema && isIncluded(schemaType, subSchema)) {
                data[subItem] = parseExample(subSchema, schemaType, true, seen);
              }
            }
          });
        }
      }
      return data;
    }
    case 'array': {
      const stringifiedSchema = stringifyRemovingCyclicFields(schema);
      // cyclic reference, return empty list
      if (seen.has(stringifiedSchema)) return [];
      seen.add(stringifiedSchema);
      return schema.items
        ? [parseExample(schema.items, schemaType, true, seen)]
        : [];
    }
    default: {
      return null;
    }
  }
}
