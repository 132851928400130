import { ApiDto } from '@portals/shared/portal/ApiDto';
import { useMemo } from 'react';
import useSWR from 'swr';

import ApiPage from '../../components/ApiPage';
import { API_EXPLORER_TABS } from '../../content';

export default function ReviewApis() {
  const { data: apis, mutate } = useSWR<ApiDto[]>('/api');
  const reviewApis = useMemo(() => {
    return apis?.filter((api) => api.stage === 'review');
  }, [apis]);
  return (
    <ApiPage
      apiType={API_EXPLORER_TABS.Review}
      apis={reviewApis || null}
      onApiFavouriteChange={(apiId, isFavorite) =>
        mutate((apis) => {
          if (apis) {
            const updatedApis = [...apis];
            const index = updatedApis.findIndex((api) => api.id === apiId);
            updatedApis[index].isFavorite = isFavorite;
            return [...updatedApis];
          }
          return;
        }, false)
      }
      title="Review APIs"
    />
  );
}
