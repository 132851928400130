import { Anchor, Div, GlobalError, Section } from '@dnb/eufemia';
import type {
  ApiFromCommonRepoDto,
  VersionFromCommonRepoDto,
} from '@portals/shared/portal/ApiFromCommonRepoDto';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Page from '@/components/Page';
import ReferenceFromCommonRepo from '@/pages/explorer/apis/ReferenceFromCommonRepo';

import Header from './components/Header';

import './styles/override.css';

const GITLAB_BASE_URL =
  'https://gitlab.tech.dnb.no/dnb/api-governance/api-contracts/-/blob/main';

interface CommonRepoApiDocumentationProps {
  apis: ApiFromCommonRepoDto[];
}
const CommonRepoApiDocumentation = ({
  apis,
}: CommonRepoApiDocumentationProps) => {
  const {
    tab: tabParam = 'reference',
    apiSlug,
    environment: environmentParam,
    version: versionParam,
  } = useParams();
  const navigate = useNavigate();
  const [environment, setEnvironment] = useState('production');
  const [version, setVersion] = useState<VersionFromCommonRepoDto>();

  const api = useMemo(() => {
    return apis.find((api) => api.slug === apiSlug);
  }, [apiSlug, apis]);

  const environmentDropdown = useMemo(() => {
    return [
      ...new Set(api?.versions.map((version) => version.environment)),
    ].map((environment) => ({
      content: environment,
      key: environment,
    }));
  }, [api?.versions]);

  const releaseDropdown = useMemo(() => {
    return api?.versions
      .filter((version) => version.environment === environment)
      .map((version) => ({
        content: version.version || '',
        key: version.version || '',
      }));
  }, [api?.versions, environment]);

  function update(type: string, key: string) {
    if (type === 'environment') {
      doNavigate({ env: key });
    } else if (type === 'release') {
      doNavigate({ release: key });
    }
  }
  const doNavigate = useCallback(
    ({ env = '', release = '', tab = '', replace = true }) => {
      let url = `/api-contracts/${apiSlug}`;
      url += env || environmentParam ? '/' + (env || environmentParam) : '';
      url += release || versionParam ? '/' + (release || versionParam) : '';
      url += tab || tabParam ? '/' + (tab || tabParam) : '';
      url += window.location.hash ?? window.location.hash;
      navigate(url, { replace: replace });
    },
    [versionParam, environmentParam, tabParam, apiSlug, navigate],
  );

  /* 2. SET ALL ENVIRONMENTS */
  useEffect(() => {
    if (api) {
      const env =
        !environmentParam || environmentParam === '@default'
          ? (environmentDropdown.at(-1)?.key ?? '')
          : environmentParam;
      setEnvironment(env);
      doNavigate({ env });
    }
  }, [environmentParam, apiSlug, doNavigate, api, environmentDropdown]);

  /* 3. SET ALL RELEASES */
  useEffect(() => {
    if (api && environment) {
      const ver =
        !versionParam || versionParam === '@latest' ? null : versionParam;

      const versionPerEnv = api.versions.filter(
        (version) => version.environment === environment,
      );

      const latestVersion = versionPerEnv.find((version) =>
        ver ? version.version === versionParam : version.isLatest,
      );
      const _version = latestVersion || versionPerEnv[0];
      setVersion(_version);
      doNavigate({
        release: _version?.version,
      });
    }
  }, [api, doNavigate, environment, version?.version, versionParam]);

  return (
    <div className="dark-mode">
      <Page
        component={
          <>
            <Header
              canSubscribe={false}
              environments={environmentDropdown}
              releases={releaseDropdown}
              selected={apiSlug ?? ''}
              selectedEnvironment={environment}
              selectedVersion={version?.version || ''}
              setEnv={update}
            />
            {version && (
              <Div top="medium">
                <Anchor
                  className="dnb-anchor"
                  href={`${GITLAB_BASE_URL}/${version?.apiLink}`}
                  rel="noreferrer"
                  target="_blank"
                  title="Open in gitlab"
                >
                  Open in gitlab
                </Anchor>
              </Div>
            )}
          </>
        }
        noContainer
        spacing={false}
        styleType="white"
        title={api?.title}
      >
        <Section style_type="white">
          {version?.apiLink ? (
            <ReferenceFromCommonRepo apiLink={version?.apiLink} />
          ) : (
            <GlobalError title="No reference present" />
          )}
        </Section>
      </Page>
    </div>
  );
};

export default CommonRepoApiDocumentation;
