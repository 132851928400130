import { Dropdown } from '@dnb/eufemia';
import { SchemaType } from '@portals/shared/common/open-api/openapi-helpers';
import { useState } from 'react';

import { DereferencedMediaTypeObject } from '@/pages/api-documentation/constants/types';

import MediaType from './components/MediaType';

interface Props {
  content: { [media: string]: DereferencedMediaTypeObject };
  schemaType: SchemaType;
}

export default function MediaTypeContent({
  content,
  schemaType,
}: Props): JSX.Element {
  const contentTypes = Object.keys(content);
  const [selectedContentType, setSelectedContentType] = useState(
    contentTypes[0],
  );

  const selectedMediaTypeObject = content[selectedContentType];

  return (
    <div>
      <Dropdown
        bottom="small"
        data={contentTypes}
        // @ts-ignore property 'data' does in fact exist
        onChange={({ data }) => setSelectedContentType(data)}
        title="Content type"
        top="small"
        value={selectedContentType}
      />
      {selectedMediaTypeObject && (
        <MediaType
          contentType={selectedContentType}
          mediaTypeObject={selectedMediaTypeObject}
          schemaType={schemaType}
        />
      )}
    </div>
  );
}
