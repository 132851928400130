import { Tabs } from '@dnb/eufemia';
import { parseExample } from '@portals/shared/common/open-api/example-resolver';
import {
  getSchemaName,
  isUnionSchema,
  resolveSchema,
  Schema,
  SchemaType,
  schemaTypeDisplayName,
} from '@portals/shared/common/open-api/openapi-helpers';

import Example from '../Example';

import style from './index.module.css';

type Props = {
  schema: Schema;
  schemaType: SchemaType;
  contentType: string;
};

const SchemaExample = ({
  schema: unresolvedSchema,
  schemaType,
  contentType,
}: Props) => {
  const schema = isUnionSchema(unresolvedSchema)
    ? unresolvedSchema
    : resolveSchema(unresolvedSchema);

  if (isUnionSchema(schema)) {
    return (
      <div className={style['union-container']}>
        <p className={style['union-type']}>
          {schemaTypeDisplayName[schema.type]}
        </p>
        <Tabs
          content_spacing={false}
          data={schema.schemas.map((subSchema, index) => ({
            title: getSchemaName(subSchema),
            key: index.toString(), // If the key is a number then the contents of the first tab is not shown
            content: (
              <SchemaExample
                contentType={contentType}
                schema={subSchema}
                schemaType={schemaType}
              />
            ),
          }))}
          no_border
        />
      </div>
    );
  }

  // Currently we only support creating examples if the contentType is application/json
  const createExample = contentType === 'application/json';

  const exampleData = parseExample(schema, schemaType, createExample);

  return <Example contentType={contentType} example={exampleData} />;
};

export default SchemaExample;
