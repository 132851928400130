import { Paragraph } from '@dnb/eufemia';
import { SchemaType } from '@portals/shared/common/open-api/openapi-helpers';

import { DereferencedSchemaObject } from '@/pages/api-documentation/constants/types';
import SchemaItem from '@/pages/api-documentation/sections/reference/components/ReferenceView/components/Item/components/SchemaItem';

export default function SchemaView({
  schema,
  schemaType,
}: {
  schema: DereferencedSchemaObject | undefined;
  schemaType: SchemaType;
}) {
  if (schema) return <SchemaItem schema={schema} schemaType={schemaType} />;

  return (
    <Paragraph top="small">
      <i>Schema not provided.</i>
    </Paragraph>
  );
}
