import { Code, Grid, HelpButton, Link, P, Space } from '@dnb/eufemia';
import { PropsWithChildren, useContext } from 'react';

import Markdown from '@/components/Markdown';
import { DereferencedSchemaObject } from '@/pages/api-documentation/constants/types';
import { DiscriminatorContext } from '@/pages/api-documentation/sections/reference/components/ReferenceView/components/Item/components/SchemaItem/context/DiscriminatorContext';

import NameAndRequired from '../NameAndRequired';

import style from './index.module.css';

const range = (min?: number, max?: number): string | null => {
  if (min != null && max != null) {
    return `[${min}, ${max}]`;
  }

  if (max != null) {
    return `≤ ${max}`;
  }

  if (min != null) {
    return `≥ ${min}`;
  }

  return null;
};

interface Props {
  schema: DereferencedSchemaObject;
  name?: string;
  required?: boolean;
}

export default function SchemaItemDescription({
  schema,
  name,
  required,
}: Props) {
  const discriminatorContext = useContext(DiscriminatorContext);

  const isDiscriminator = discriminatorContext
    ? discriminatorContext.discriminator.propertyName === name
    : false;

  return (
    <Grid.Container columnGap="small">
      <Grid.Item
        span={{
          small: [1, 12],
          medium: [1, 12],
          large: [1, 4],
        }}
      >
        <NameAndRequired name={name} required={required} />
        <P className={style['type']} top="xx-small">
          {schema?.type} {schema?.format && `<${schema.format}>`}
          {schema?.nullable && ' | null'}
        </P>
        {isDiscriminator && (
          <P className={style['discriminator']} top="xx-small">
            discriminator{' '}
            <HelpButton size="small" title="discriminator property">
              The value of this property determines which schema is used from
              the <Code>anyOf</Code>/<Code>oneOf</Code> options.
            </HelpButton>
          </P>
        )}
        {schema?.deprecated && (
          <P
            className={style['deprecated']}
            space={{ top: '0.5rem' }}
            top="xx-small"
          >
            Deprecated
          </P>
        )}
      </Grid.Item>
      <Grid.Item
        span={{
          small: [1, 12],
          medium: [1, 12],
          large: [5, 12],
        }}
      >
        {schema.description && <Markdown>{schema.description}</Markdown>}

        {isDiscriminator && (
          <DescriptionRow name="Value">
            <Code>{discriminatorContext!.discriminatorValue}</Code>
          </DescriptionRow>
        )}

        {schema.pattern && (
          <DescriptionRow name="Pattern">
            <Code>{schema.pattern}</Code>
          </DescriptionRow>
        )}
        {schema.enum && (
          <DescriptionRow name="Enum">
            <div className={style['enum-list']}>
              {schema.enum.map((value: string) => (
                <Code key={value}>{value}</Code>
              ))}
            </div>
          </DescriptionRow>
        )}

        {schema?.maximum && (
          <DescriptionRow name="Range">
            {range(schema.minimum, schema.maximum)}
          </DescriptionRow>
        )}
        {schema?.maxItems && (
          <DescriptionRow name="Length">
            {range(schema.minItems, schema.maxItems)}
          </DescriptionRow>
        )}
        {schema?.maxLength && (
          <DescriptionRow name="Length">
            {range(schema.minLength, schema.maxLength)}
          </DescriptionRow>
        )}

        {schema.default && (
          <DescriptionRow name="Default">
            <Code>{schema.default}</Code>
          </DescriptionRow>
        )}

        {schema?.externalDocs && (
          <div className="line">
            <Space top="x-small" />
            {(schema?.externalDocs?.description && (
              <Link href={schema.externalDocs.url} target="_blank">
                <Markdown>{schema?.externalDocs?.description}</Markdown>
              </Link>
            )) || (
              <Link href={schema.externalDocs.url} target="_blank">
                {schema.externalDocs.url}
              </Link>
            )}
          </div>
        )}
      </Grid.Item>
    </Grid.Container>
  );
}

function DescriptionRow({
  name,
  children,
}: PropsWithChildren<{
  name: string;
}>) {
  return (
    <Grid.Container columns={12} top="xx-small">
      <Grid.Item span={[1, 2]}>
        <strong>{name}:</strong>
      </Grid.Item>
      <Grid.Item span={[3, 12]}>{children}</Grid.Item>
    </Grid.Container>
  );
}
