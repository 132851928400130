import { ApiDto } from '@portals/shared/portal/ApiDto';
import { useMemo } from 'react';
import useSWR from 'swr';

import ApiPage from '../../components/ApiPage';
import { API_EXPLORER_TABS, EXTERNAL_CLASSIFICATIONS } from '../../content';

export default function CommercialApis() {
  const { data: apis, mutate } = useSWR<ApiDto[]>('/api');
  const commercialApis = useMemo(() => {
    return apis
      ?.filter((api) => EXTERNAL_CLASSIFICATIONS.has(api.classification))
      .filter(({ tags }) => !tags.includes('PSD2'));
  }, [apis]);

  return (
    <ApiPage
      apiType={API_EXPLORER_TABS.Commercial}
      apis={commercialApis || null}
      onApiFavouriteChange={(apiId, isFavorite) =>
        mutate((apis) => {
          if (apis) {
            const updatedApis = [...apis];
            const index = updatedApis.findIndex((api) => api.id === apiId);
            updatedApis[index].isFavorite = isFavorite;
            return [...updatedApis];
          }
          return;
        }, false)
      }
      title="Commercial APIs"
    />
  );
}
