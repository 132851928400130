import Markdown from '@/components/Markdown';

import GuideTableOfContent from './GuideTableOfContent';

import { Page } from '@/pages/api-documentation/styles/Api.Reference.styles';

const Guide = ({ guide }: { guide: string | undefined }): JSX.Element => {
  return (
    <Page>
      <GuideTableOfContent guide={guide} />
      <div className="content">{guide && <Markdown>{guide}</Markdown>}</div>
    </Page>
  );
};

export default Guide;
