import { FormStatus, InfoCard, ProgressIndicator } from '@dnb/eufemia';
import { BAID_MATCHER, BSNID_MATCHER } from '@portals/shared/common/matchers';
import {
  ServiceNowBusinessApplicationDto,
  ServiceNowServiceOfferingDto,
} from '@portals/shared/portal/SupportTicket';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { ApiError, request } from '@/request';

interface ServiceNowIdentifierViewerProps {
  serviceNowIdentifier?: string;
  onChange: (value: string) => void;
}

export default function ServiceNowIdentifierViewer({
  serviceNowIdentifier,
  onChange,
}: ServiceNowIdentifierViewerProps) {
  const isValidBAIdentifier = BAID_MATCHER.test(serviceNowIdentifier || '');
  const isValidBSNIdentifier = BSNID_MATCHER.test(serviceNowIdentifier || '');
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const { data, isValidating } = useSWR<
    ServiceNowBusinessApplicationDto | ServiceNowServiceOfferingDto
  >(
    isValidBAIdentifier
      ? `/service-now/business-applications/${serviceNowIdentifier}`
      : isValidBSNIdentifier
        ? `/service-now/service-offerings/${serviceNowIdentifier}`
        : null,
    async (url) => {
      return request<
        ServiceNowBusinessApplicationDto | ServiceNowServiceOfferingDto
      >(url, 'get');
    },
    {
      onError: (error) => {
        if (ApiError.isApiError(error)) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage('Something went wrong. Please try again later.');
        }
      },
      shouldRetryOnError: false,
    },
  );
  useEffect(() => {
    if (serviceNowIdentifier) {
      setErrorMessage(null);
    }
  }, [serviceNowIdentifier]);
  useEffect(() => {
    if (
      data &&
      (isValidBAIdentifier || isValidBSNIdentifier) &&
      !isValidating
    ) {
      onChange(data.id);
    } else {
      onChange('');
    }
  }, [data, isValidating, isValidBAIdentifier, isValidBSNIdentifier]);

  if (errorMessage) return <FormStatus bottom="large" text={errorMessage} />;
  if (isValidating) return <ProgressIndicator size="small" type="linear" />;
  if (!data) return null;

  return (
    <InfoCard
      dropShadow={false}
      icon=""
      text={
        'businessApplicationId' in data ? (
          <>
            {' '}
            {isValidBAIdentifier
              ? 'Business Application'
              : 'Service Offering'}{' '}
            ({data.businessApplicationId}
            )
            <br /> Approver: {data.itApplicationOwner}
          </>
        ) : (
          <>
            {' '}
            {isValidBAIdentifier
              ? 'Business Application'
              : 'Service Offering'}{' '}
            ({data.number}
            )
            <br /> Approver: {data.managedBy}
          </>
        )
      }
      title={data.name}
    />
  );
}
